import "../src/translations/i18n";
import "../styles/globals.css";
import "../styles/calendar.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import * as gtag from "../helpers/gtag";

import { DefaultSeo } from "next-seo";
import Head from "next/head";
import Script from "next/script";
import { useEffect } from "react";
import { useRouter } from "next/router";

const App = ({ Component, pageProps }) => {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageview(url);
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => router.events.off("routeChangeComplete", handleRouteChange);
  }, [router.events]);

  return (
    <>
      <DefaultSeo
        dangerouslySetAllPagesToNoIndex={
          process.env.NEXT_PUBLIC_NO_INDEX_NO_FOLLOW === "true"
        }
        dangerouslySetAllPagesToNoFollow={
          process.env.NEXT_PUBLIC_NO_INDEX_NO_FOLLOW === "true"
        }
      />
      <Head>
        <meta content="initial-scale=1.0;" name="viewport" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        {process.env.NEXT_PUBLIC_APP_NAME == "Festyful" && (
          <meta name="impact-site-verification" value="-1658377953" />
        )}

        <link
          rel="icon"
          href={
            process.env.NEXT_PUBLIC_APP_NAME == "Vybeful"
              ? "/favicon-vybeful.png"
              : process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub"
              ? "/favicon-rooftopclub.png"
              : process.env.NEXT_PUBLIC_APP_NAME == "Cocktayl"
              ? "/favicon-cocktayl.png"
              : process.env.NEXT_PUBLIC_APP_NAME == "SpaClub"
              ? "/favicon-spaclub.png"
              : process.env.NEXT_PUBLIC_APP_NAME == "Beachful"
              ? "/favicon-beachful.png"
              : process.env.NEXT_PUBLIC_APP_NAME == "Festyful"
              ? "/favicon-festyful.png"
              : process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld"
              ? "/favicon-prefab.png"
              : process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia"
              ? "/favicon-zoopedia.png"
              : process.env.NEXT_PUBLIC_APP_NAME == "Parkful"
              ? "/favicon-parkful.png"
              : process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino"
              ? "/favicon-worldcasino.png"
              : process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia"
              ? "/favicon-castlepedia.png"
              : process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club"
              ? "/favicon-resort.png"
              : process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub"
              ? "/favicon-aparthotel.png"
              : "/favicon-new.png"
          }
          sizes="32x32"
        />

        <link href="https://cdn.jsdelivr.net/npm/animate.css@3.5.2/animate.min.css" />
        <link rel="preconnect" href="https://www.googletagmanager.com" />
      </Head>

      {process.env.NEXT_PUBLIC_GTAG_MANAGER !== "" && (
        <Script
          strategy="lazyOnload"
          defer
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GTAG_MANAGER}`}
        />
      )}
      {process.env.NEXT_PUBLIC_GTAG_MANAGER !== "" && (
        <Script
          data-cookie-consent="tracking"
          id="google-analytics"
          strategy="lazyOnload"
          defer
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GTAG_MANAGER}', {
            page_path: window.location.pathname,
          });
          `,
          }}
        />
      )}

      <Script
        defer
        type="text/javascript"
        src="https://www.termsfeed.com/public/cookie-consent/4.1.0/cookie-consent.js"
      />
      <Script
        id="cookieconsent"
        defer
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `
            cookieconsent.run({
              "notice_banner_type":"simple",
              "consent_type":"express",
              "palette":"dark",
              "language":"en",
              "page_load_consent_levels":["strictly-necessary"],
              "notice_banner_reject_button_hide":false,
              "preferences_center_close_button_hide":false,
              "page_refresh_confirmation_buttons":false,
              "website_name":"${process.env.NEXT_PUBLIC_APP_NAME}",
            });
          `,
        }}
      />

      <Component {...pageProps} />
    </>
  );
};

export default App;
